import { Button } from "ch3-ui-lib";
import { clsxMerge } from "../../../utilis/classNameUtils.ts";
import { bgStyles, borderStyles, textStyles } from "./helpers.tsx";
import { useSelector } from "react-redux";
import {RootState} from "../../../store/store";

interface DialpadButtonProps {
    label: string;
    subLabel?: string;
    className?: string;
}

interface KeypadProps {
    keypadClicked: (key: string) => void;
}

function Keypad({ keypadClicked }: KeypadProps) {
    const callState = useSelector((state: RootState) => state.dialer.callState);
    const theme = useSelector((state: any) => state.theme.theme);

    const dialpadButtons = [
        { label: "1" },
        { label: "2", subLabel: "ABC" },
        { label: "3", subLabel: "DEF" },
        { label: "4", subLabel: "GHI" },
        { label: "5", subLabel: "JKL" },
        { label: "6", subLabel: "MNO" },
        { label: "7", subLabel: "PQRS" },
        { label: "8", subLabel: "TUV" },
        { label: "9", subLabel: "WXYZ" },
        {
            label: callState === "IN_CALL" ? "*" : "+",
            className: "items-center text-xl font-bold leading-5 text-black rounded-sm bg-zinc-100",
        },
        { label: "0", subLabel: "+" },
        {
            label: "#",
            className: "items-center text-xl font-bold leading-5 text-black rounded-sm bg-zinc-100",
        },
    ];

    return (
        <div
            className={`flex flex-col justify-center pb-6 pt-0 w-full whitespace-nowrap max-w-[212px] mx-auto ${
                theme === "dark" ? "bg-[#1C1E21]" : "bg-white"
            }`}
        >
            {[0, 3, 6, 9].map((startIndex) => (
                <div className="flex gap-5 justify-between mt-6" key={startIndex}>
                    {dialpadButtons.slice(startIndex, startIndex + 3).map((button: DialpadButtonProps) => (
                        <Button
                            key={button.label}
                            className={`w-12 h-12 px-6 py-6 font-bold border-1 border ${
                                theme === "dark"
                                + clsxMerge(borderStyles({theme}), bgStyles({theme}), textStyles({theme}))}`}
                            size={"large"}
                            buttonType={"secondary"}
                            label={button.label}
                            subLabel={button.subLabel}
                            onClick={() => keypadClicked(button.label)}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}

export default Keypad;
