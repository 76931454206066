import {Avatar, ChCheckbox} from "ch3-ui-lib";
import {clsxMerge} from "../../../utilis/classNameUtils";
import {borderStyles} from "../../Groups/helpers";
import {useSelector} from "react-redux";

export const UserRow = ({user, isSelected, handleSelect}: { user: any,isSelected: boolean, handleSelect: any  }) => {
    const theme = useSelector((state: any) => state.theme.theme);
    return (
        <td className={'flex items-center gap-3 p-3 border-b ' + clsxMerge(borderStyles({theme}))}>
            <ChCheckbox  callback={() => handleSelect(user)}
            checked={isSelected}
            value={user}/>
            <Avatar size={'md'} type={'icon'} icon={'person'}  color={'default'}/>
            <div>
                <div className='font-semibold'>{user.firstName + ' ' + user.lastName}</div>
                <div>{user.email}</div>
            </div>

        </td>
    );
}
