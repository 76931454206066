import { configureStore} from "@reduxjs/toolkit";
import callsReducer from "./features/calls/callsSlice";
import dialerReducer from "./features/calls/dialerSlice";
import groupsReducer from "./features/groups/groupsSlice";
import usersReducer from "./features/Users/usersSlice";
import msisdnsReducer from "./features/Msisdns/msisdnsSlice";
import contactsReducer from "./features/Contacts/contactsSlice";
import searchReducer from "./features/Searches/searchSlice";
import msisdnSettingsReducer from "./features/MsisdnSettings/msisdnSettingsSlice";
import toastReducer from "./features/Notifications/notificationsSlice";
import filtersReducer from "./features/Filters/filtersSlice";
import teamSlice from "./features/Team/teamSlice"
import profileSettingsReducer from "./features/ProfileSettings/profileSettingsSlice"
import integrationsReducer from "./features/integrations/integrationsService"

import {useDispatch} from "react-redux";
import themeSwitcherSlice from "./features/ThemeSwitcher/themeSwitcherSlice";
export const store
    = configureStore({
    reducer: {
        auth: callsReducer,
        calls: callsReducer,
        dialer: dialerReducer,
        groups: groupsReducer,
        users: usersReducer,
        msisdns: msisdnsReducer,
        contacts: contactsReducer,
        msisdnSettings: msisdnSettingsReducer,
        toast: toastReducer,
        search: searchReducer,
        filters: filtersReducer,
        team: teamSlice,
        profileSettings: profileSettingsReducer ,
        integrations: integrationsReducer,
    theme: themeSwitcherSlice
  }
});
export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>
