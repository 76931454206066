import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'; // Import Axios
import {filterParams} from "../calls/callsSlice";
import {getApiUrl} from "../../../config";

export interface MsisdnSelectItem {
    msisdnId: number;
    label: string;
    icon: string;
    type: string;
    msisdn: number;
}

interface MsisdnsState {
    msisdns: Msisdn[];
    totalCount: number,
    status: string;
    msisdnsByPhrase: Msisdn[];
    userMsisdns: MsisdnSelectItem[];
    error: string | undefined;
}

export interface Msisdn {
    "msisdnId": number,

    "msisdn": number,
    "label": string,
    "icon": string,
    "type": string
}

const getHeaders = () => ({
    'Content-Type': 'application/json',
    'tenant': localStorage.getItem('tenant'),
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
});

const initialState: MsisdnsState = {
    msisdns: [],
    totalCount: 0,
    userMsisdns: [],
    msisdnsByPhrase: [],
    status: 'idle',
    error: undefined,
};

// Async thunk for POST /msisdn
export const postMsisdn = createAsyncThunk('msisdns/postMsisdn', async (msisdn: Msisdn) => {
    const response = await axios.post(`${await getApiUrl()}/msisdn`, msisdn, { headers: getHeaders() });
    return response.data;
});

// Async thunk for PATCH /msisdn
export const patchMsisdn = createAsyncThunk('msisdns/patchMsisdn', async ({ id, msisdn }: { id: number; msisdn: Msisdn }) => {
    const response = await axios.patch(`${await getApiUrl()}/msisdn/${id}`, msisdn, { headers: getHeaders() });
    return response.data;
});

// Async thunk for GET /msisdn
export const fetchMsisdns = createAsyncThunk('msisdns/getAllMsisdns', async () => {
    const user = localStorage.getItem('userId');
    console.log('user', user, await axios.get(`${await getApiUrl()}/msisdn/user/${user}`, { headers: getHeaders() }) )
    const response = await axios.get(`${await getApiUrl()}/msisdn/user/${user}`, { headers: getHeaders() });
    return response.data;
});

// Async thunk for GET one /msisdn/:id
export const getOneMsisdn = createAsyncThunk('msisdns/getOneMsisdn', async (id: number) => {
    const response = await axios.get(`${await getApiUrl()}/msisdn/${id}`, { headers: getHeaders() });
    return response.data;
});

// Async thunk for DELETE /msisdn/:id
export const deleteMsisdn = createAsyncThunk('msisdns/deleteMsisdn', async (id: number) => {
    await axios.delete(`${await getApiUrl()}/msisdn/${id}`, { headers: getHeaders() });
    return id;
});
export const getUserMsisdns = createAsyncThunk<any, string | undefined>('msisdns/getUserMsisdns', async (phrase?) => {

    const response = await axios.get(`${await getApiUrl()}/msisdn`,
        {
            headers: getHeaders(),
            params: filterParams({ phrase }),});
    return response;
});

export const getMsidnsByPhrase = createAsyncThunk<any, string | undefined>('msisdns/getMsisdnsByPhrase', async (phrase?) => {
    const userId = localStorage.getItem('userId')
    const response = await axios.get(`${await getApiUrl()}/msisdn/user/${userId}`,
        {
            headers: getHeaders(),
            params: filterParams({ phrase }),});
    return response.data;
});

export const msisdnsSlice = createSlice({
    name: 'msisdns',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postMsisdn.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postMsisdn.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.msisdns.push(action.payload);
            })
            .addCase(postMsisdn.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(patchMsisdn.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(patchMsisdn.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.msisdns.findIndex(msisdn => msisdn.msisdnId === action.payload.msisdnId);
                if (index !== -1) {
                    state.msisdns[index] = action.payload;
                }
            })
            .addCase(patchMsisdn.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchMsisdns.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMsisdns.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.msisdns = action.payload;

            })
            .addCase(fetchMsisdns.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getOneMsisdn.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getOneMsisdn.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.msisdns.findIndex(msisdn => msisdn.msisdnId === action.payload.msisdnId);
                if (index === -1) {
                    state.msisdns.push(action.payload);
                } else {
                    state.msisdns[index] = action.payload;
                }
            })
            .addCase(getOneMsisdn.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteMsisdn.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteMsisdn.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.msisdns = state.msisdns.filter(msisdn => msisdn.msisdnId !== action.payload);
            })
            .addCase(deleteMsisdn.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getUserMsisdns.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUserMsisdns.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userMsisdns = action.payload.data;
                state.totalCount = action.payload.headers['x-total-count'];

            })
            .addCase(getUserMsisdns.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getMsidnsByPhrase.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.msisdnsByPhrase = action.payload;
            })
    },
});

export const selectUserMsisdns = (state: { msisdns: { userMsisdns: MsisdnSelectItem[]; }; }) => state.msisdns.userMsisdns;

export const selectTotalMsisdnsCount = (state: { msisdns: { totalCount: number; }; }) => state.msisdns.totalCount;

export default msisdnsSlice.reducer;
